import type {FC} from 'react';
import React from 'react';
import clsx from 'clsx';
import type {ControlProps} from 'nuka-carousel';

import {ArrowLeftIcon} from '@/modules/foundation/components/icons/arrow-left-icon';
import {ArrowRightIcon} from '@/modules/foundation/components/icons/arrow-right-icon';

const buttonBase = clsx(
	'border-none',
	'cursor-pointer',
	'disabled:cursor-not-allowed',
	// 'm-2',
	'flex',
	'appearance-none',
	'items-center',
	'text-black',
	'opacity-70',
	'hover:opacity-100',
	'disabled:opacity-30',
	'bg-white',
	'rounded-full',
	'p-1',
	'mx-2',
);

export const renderCenterLeftControls: FC<ControlProps> = ({previousDisabled, previousSlide}) => (
	<button
		className={buttonBase}
		disabled={previousDisabled}
		onClick={previousSlide}
		aria-label="Gå til forrige slide"
	>
		<ArrowLeftIcon size={32} />
	</button>
);

export const renderCenterRightControls: FC<ControlProps> = ({nextDisabled, nextSlide}) => (
	<button
		className={buttonBase}
		disabled={nextDisabled}
		onClick={nextSlide}
		aria-label="Gå til neste slide"
	>
		<ArrowRightIcon size={32} />
	</button>
);
