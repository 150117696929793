import {groq} from 'next-sanity';

import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {Project} from '../../shared/types';
import type {ProjectInCardFragment} from '../query';
import {PROJECT_IN_CARD_FRAGMENT, projectInCardFragmentToProjectDetails} from '../query';

import type {BLOCK_TYPE_PROJECT_LIST_SELECTION} from './constants';
import type {ProjectListSelectionSchema} from './schema';

export const PROJECT_LIST_SELECTION_FRAGMENT = groq`
	...,
	projects[]-> {
		${PROJECT_IN_CARD_FRAGMENT}
	}
`;

export type ProjectListSelectionFragmentType = Omit<ProjectListSelectionSchema, 'projects'> & {
	_type: typeof BLOCK_TYPE_PROJECT_LIST_SELECTION;
	projects: (ProjectInCardFragment | null)[];
};

export const projectListFromProjectListSelectionSection = (
	section: ProjectListSelectionFragmentType,
): KeyedArray<Project> => {
	const projects: KeyedArray<Project> = [];

	if (!section.projects?.length) {
		return projects;
	}

	for (const projectInCard of section.projects) {
		if (projectInCard) {
			const project = projectInCardFragmentToProjectDetails(projectInCard);
			if (project) {
				projects.push({
					_key: projectInCard._id,
					...project,
				});
			}
		}
	}

	return projects;
};
