import {groq} from 'next-sanity';

import {BLOCK_TYPE_ARTICLE_LIST_ALL} from '@/modules/article/sanity/article-list-all-container/constants';
import type {ArticleListAllFragment} from '@/modules/article/sanity/article-list-all-container/query';
import {ARTICLE_LIST_ALL_FRAGMENT} from '@/modules/article/sanity/article-list-all-container/query';
import {BLOCK_TYPE_ARTICLE_LIST_SELECTION} from '@/modules/article/sanity/article-list-selection-container/constants';
import type {ArticleListSelectionFragmentType} from '@/modules/article/sanity/article-list-selection-container/query';
import {ARTICLE_LIST_SELECTION_FRAGMENT} from '@/modules/article/sanity/article-list-selection-container/query';
import type {SanityContactFormSectionSchema} from '@/modules/contact-form/sanity/contact-form-section-container/contact-form-section';
import type {GallerySchema} from '@/modules/page/sanity/content-blocks/gallery-container/schema';
import {BLOCK_TYPE_LINK_LIST_SELECTION} from '@/modules/page/sanity/content-blocks/link-list-selection-container/constants';
import type {LinkListSelectionFragmentType} from '@/modules/page/sanity/content-blocks/link-list-selection-container/query';
import {LINK_LIST_SELECTION_FRAGMENT} from '@/modules/page/sanity/content-blocks/link-list-selection-container/query';
import {BLOCK_TYPE_TEXT} from '@/modules/page/sanity/content-blocks/text-container/constants';
import type {TextSectionFragmentType} from '@/modules/page/sanity/content-blocks/text-container/query';
import {TEXT_SECTION_FRAGMENT} from '@/modules/page/sanity/content-blocks/text-container/query';
import {BLOCK_TYPE_TEXT_WITH_IMAGE_SPLIT} from '@/modules/page/sanity/content-blocks/text-with-image-split-container/constants';
import type {TextWithImageSplitFragment} from '@/modules/page/sanity/content-blocks/text-with-image-split-container/query';
import {TEXT_WITH_IMAGE_SPLIT_FRAGMENT} from '@/modules/page/sanity/content-blocks/text-with-image-split-container/query';
import {BLOCK_TYPE_PERSON_LIST_ALL} from '@/modules/person/sanity/person-list-all-container/constants';
import type {PersonListAllFragment} from '@/modules/person/sanity/person-list-all-container/query';
import {PERSON_LIST_ALL_FRAGMENT} from '@/modules/person/sanity/person-list-all-container/query';
import {BLOCK_TYPE_PERSON_LIST_GROUP} from '@/modules/person/sanity/person-list-group-container/constants';
import type {PersonListGroupFragment} from '@/modules/person/sanity/person-list-group-container/query';
import {PERSON_LIST_GROUP_FRAGMENT} from '@/modules/person/sanity/person-list-group-container/query';
import {BLOCK_TYPE_PERSON_LIST_SELECTION} from '@/modules/person/sanity/person-list-selection-container/constants';
import type {PersonListSelectionFragment} from '@/modules/person/sanity/person-list-selection-container/query';
import {PERSON_LIST_SELECTION_FRAGMENT} from '@/modules/person/sanity/person-list-selection-container/query';
import {BLOCK_TYPE_PROJECT_LIST_ALL} from '@/modules/project/sanity/project-list-all-container/constants';
import type {ProjectListAllFragment} from '@/modules/project/sanity/project-list-all-container/query';
import {PROJECT_LIST_ALL_FRAGMENT} from '@/modules/project/sanity/project-list-all-container/query';
import {BLOCK_TYPE_PROJECT_LIST_SELECTION} from '@/modules/project/sanity/project-list-selection-container/constants';
import type {ProjectListSelectionFragmentType} from '@/modules/project/sanity/project-list-selection-container/query';
import {PROJECT_LIST_SELECTION_FRAGMENT} from '@/modules/project/sanity/project-list-selection-container/query';

export const CONTENT_BLOCK_FRAGMENT = groq`
	...,

	// Klart foundation
	_type == "${BLOCK_TYPE_TEXT}" => {
		${TEXT_SECTION_FRAGMENT}
	},
	_type == "${BLOCK_TYPE_TEXT_WITH_IMAGE_SPLIT}" => {
		${TEXT_WITH_IMAGE_SPLIT_FRAGMENT}
	},
	_type == "${BLOCK_TYPE_LINK_LIST_SELECTION}" => {
		${LINK_LIST_SELECTION_FRAGMENT}
	},

	// Article extension
	_type == "${BLOCK_TYPE_ARTICLE_LIST_SELECTION}" => {
		${ARTICLE_LIST_SELECTION_FRAGMENT}
	},
	_type == "${BLOCK_TYPE_ARTICLE_LIST_ALL}" => {
		${ARTICLE_LIST_ALL_FRAGMENT}
	},

	// Person extension
	_type == "${BLOCK_TYPE_PERSON_LIST_SELECTION}" => {
		${PERSON_LIST_SELECTION_FRAGMENT}
	},
	_type == "${BLOCK_TYPE_PERSON_LIST_ALL}" => {
		${PERSON_LIST_ALL_FRAGMENT}
	},
	_type == "${BLOCK_TYPE_PERSON_LIST_GROUP}" => {
		${PERSON_LIST_GROUP_FRAGMENT}
	},

	// Project extension
	_type == "${BLOCK_TYPE_PROJECT_LIST_ALL}" => {
		${PROJECT_LIST_ALL_FRAGMENT}
	},
	_type == "${BLOCK_TYPE_PROJECT_LIST_SELECTION}" => {
		${PROJECT_LIST_SELECTION_FRAGMENT}
	},
`;

export type ContentBlockFragment =
	| TextSectionFragmentType
	| TextWithImageSplitFragment
	| GallerySchema
	| LinkListSelectionFragmentType
	| ArticleListSelectionFragmentType
	| ArticleListAllFragment
	| PersonListSelectionFragment
	| PersonListAllFragment
	| PersonListGroupFragment
	| SanityContactFormSectionSchema
	| ProjectListAllFragment
	| ProjectListSelectionFragmentType;
