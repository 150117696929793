import React from 'react';
import {CheckmarkCircleIcon, ExclamationmarkTriangleIcon} from '@navikt/aksel-icons';
import type {VariantProps} from 'class-variance-authority';
import {cva} from 'class-variance-authority';

import {cn} from '@/modules/foundation/shared/tailwind';

const responseMessage = cva(['p-4', 'border', 'rounded', 'flex', 'gap-1'], {
	variants: {
		success: {
			true: ['text-green-800', 'bg-green-50', 'border-green-700'],
			false: ['text-red-800', 'bg-red-50', 'border-red-700'],
		},
	},
});

type ResponseMessageProps = VariantProps<typeof responseMessage> & {
	message: string;
};

export const ResponseMessage = ({success, message}: ResponseMessageProps) => {
	return (
		<div className={cn(responseMessage({success}))}>
			{success ? (
				<CheckmarkCircleIcon aria-hidden="true" fontSize="1.5rem" />
			) : (
				<ExclamationmarkTriangleIcon aria-hidden="true" fontSize="1.5rem" />
			)}
			<span role="status">{message}</span>
		</div>
	);
};
