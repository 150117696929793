import type {FC} from 'react';
import clsx from 'clsx';
import Link from 'next/link';

import {ImageBlock} from '@/modules/foundation/components/image/ImageBlock';
import {Heading} from '@/modules/foundation/components/typography/heading';
import {Paragraph} from '@/modules/foundation/components/typography/paragraph';

import type {Project} from '../../shared/types';

type Props = {
	project: Project;
	headingLevel: 2 | 3;
};

export const ProjectCard: FC<Props> = ({headingLevel, project}) => {
	const {title, description, imageWeb, href} = project;

	return (
		<Link href={href} className={clsx('relative', 'group')}>
			{imageWeb && imageWeb.image && (
				<ImageBlock
					alt={imageWeb.altText ?? ''}
					height={imageWeb.image.height}
					width={imageWeb.image.width}
					src={imageWeb.image.src}
					className={clsx('rounded')}
				/>
			)}

			<div
				className={clsx(
					'bg-primary-light',
					'text-black',
					'rounded',
					'max-w-[90%]',
					'mx-auto',
					'p-4',
					'relative',
					'-mt-4',
				)}
			>
				<Heading
					level={headingLevel}
					size="xlarge"
					spacing
					className={clsx('group-hover:underline', 'group-focus:underline')}
				>
					{title}
				</Heading>

				<Paragraph className={clsx('line-clamp-3')}>{description}</Paragraph>
			</div>
		</Link>
	);
};
