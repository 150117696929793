import type {FC} from 'react';
import clsx from 'clsx';

import ImageFill from '@/modules/foundation/components/image/ImageFill';
import type {ImageWeb} from '@/modules/foundation/components/image/types';
import {Link} from '@/modules/foundation/components/link';

interface Props {
	href: string;
	title: string;
	image?: ImageWeb;
}

const LinkCard: FC<Props> = ({image, href, title}) => {
	return (
		<Link href={href} className={clsx('no-underline', 'hover:underline', 'focus:underline')}>
			<div className={clsx('flex', 'flex-col', 'justify-center', 'items-center')}>
				<div
					className={clsx('relative', 'rounded-full', 'overflow-hidden', 'size-[200px]')}
				>
					{image && (
						<ImageFill
							src={image.image.src}
							width={image.image.width}
							height={image.image.height}
							hotspot={image.image.hotspot}
							alt={image.altText ?? ''}
						/>
					)}
				</div>
				<div
					className={clsx(
						'bg-primary-light',
						'p-4',
						'rounded',
						'w-full',
						'relative',
						'-mt-4',
					)}
				>
					{title}
				</div>
			</div>
		</Link>
	);
};

export default LinkCard;
