import type {FC} from 'react';
import clsx from 'clsx';

import {ImageBlock} from '@/modules/foundation/components/image/ImageBlock';
import {Paragraph} from '@/modules/foundation/components/typography/paragraph';
import {createPhoneLink, formatPhoneNumber} from '@/modules/foundation/shared/format';

import type {Person} from '../../shared/types';

export interface PersonCardProps {
	person: Person;
}

export const PersonCard: FC<PersonCardProps> = ({person}) => {
	const {name, image, phone, email, role} = person;

	return (
		<div className={clsx('relative')}>
			{image && (
				<ImageBlock
					alt={image.altText ?? ''}
					height={image.image.height}
					width={image.image.width}
					src={image.image.src}
					className={clsx('rounded')}
				/>
			)}

			{!image && (
				<div
					className={clsx(
						'aspect-[1]',
						'bg-gray-200',
						'flex',
						'items-center',
						'justify-center',
						'text-gray-600',
						'p-4',
					)}
				>
					Mangler bilde
				</div>
			)}

			<div
				className={clsx(
					'bg-secondary',
					'rounded',
					'p-6',
					'w-[90%]',
					'mx-auto',
					'relative',
					'-mt-4',
				)}
			>
				{name && <Paragraph className={clsx('font-bold')}>{name}</Paragraph>}

				{role && <Paragraph>{role}</Paragraph>}

				{phone && (
					<Paragraph asChild>
						<a href={createPhoneLink(phone)} className={clsx('block')}>
							{formatPhoneNumber(phone)}
						</a>
					</Paragraph>
				)}

				{email && (
					<Paragraph asChild>
						<a href={`mailto:${email}`} className={clsx('block')}>
							{email}
						</a>
					</Paragraph>
				)}
			</div>
		</div>
	);
};
