import {toPlainText} from '@portabletext/react';
import {groq} from 'next-sanity';

import {imageWebFromImageWebSchema} from '@/modules/foundation/sanity/image/query';
import {internalLinkToHref} from '@/modules/foundation/sanity/link/query';

import type {Project} from '../shared/types';

import {DOCUMENT_TYPE_PROJECT} from './constants';
import type {ProjectSchema} from './schema';

export const PROJECT_IN_CARD_FRAGMENT = groq`
	_id,
	title,
	slug,
	image,
	description
`;

export type ProjectInCardFragment = Pick<
	ProjectSchema,
	'_id' | 'title' | 'slug' | 'description' | 'image'
>;

export function projectInCardFragmentToProjectDetails(project: ProjectInCardFragment): Project {
	return {
		title: project.title ?? '',
		href: internalLinkToHref(DOCUMENT_TYPE_PROJECT, project.slug?.current),
		description: project.description ? toPlainText(project.description) : '',
		imageWeb: project?.image?.asset
			? imageWebFromImageWebSchema(project.image, 4 / 3)
			: undefined,
	};
}
