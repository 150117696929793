import {type Dispatch, type FC, type SetStateAction, useCallback} from 'react';
import {Dialog} from '@headlessui/react';
import clsx from 'clsx';
import Carousel from 'nuka-carousel';

import {FullScreenExitIcon} from '@/modules/foundation/components/icons/full-screen-exit-icon';
import ImageFill from '@/modules/foundation/components/image/ImageFill';
import type {ImageWeb} from '@/modules/foundation/components/image/types';
import {usePrefersReducedMotion} from '@/modules/foundation/hooks/usePrefersReducedMotion';
import type {KeyedArray} from '@/modules/foundation/shared/types';

import {renderCenterLeftControls, renderCenterRightControls} from './controls-lightbox';

interface Props {
	initialIndex: number;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	isOpen: boolean;
	images: KeyedArray<ImageWeb>;
}

const defaultControlsConfig = {
	pagingDotsClassName: 'm-2 invert',
	pagingDotsContainerClassName: '!absolute !top-[100%] -translate-x-1/2',
};

export const Lightbox: FC<Props> = ({initialIndex, setIsOpen, isOpen, images}) => {
	const prefersReducedMotion = usePrefersReducedMotion();

	const hasMultipleImages = images.length > 1;

	const handleClose = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	return (
		<Dialog open={isOpen} onClose={handleClose} className={clsx('relative', 'z-50')}>
			{/* Full-screen container to center the panel */}
			<div
				className={clsx(
					'fixed',
					'inset-0',
					'flex',
					'w-screen',
					'h-screen',
					'items-center',
					'justify-center',
				)}
			>
				{/* The actual dialog panel  */}
				<Dialog.Panel
					className={clsx(
						'bg-black',
						'w-full',
						'h-full',
						'max-h-screen',
						'text-white',
						'py-[10svh]',
						'sm:px-[10vw]',
					)}
				>
					<Dialog.Title className={clsx('sr-only')}>Fullskjerm bildegalleri</Dialog.Title>
					<ExitFullscreenButton onClick={handleClose} />

					{hasMultipleImages ? (
						<Carousel
							renderCenterLeftControls={renderCenterLeftControls}
							renderCenterRightControls={renderCenterRightControls}
							slideIndex={initialIndex}
							disableAnimation={prefersReducedMotion}
							disableEdgeSwiping={true}
							adaptiveHeight={true}
							defaultControlsConfig={defaultControlsConfig}
						>
							{images.map((image) => (
								<LightboxImage image={image} key={image._key} />
							))}
						</Carousel>
					) : (
						<LightboxImage image={images[0]} />
					)}
				</Dialog.Panel>
			</div>
		</Dialog>
	);
};

type LightboxImageProps = {
	image: ImageWeb;
};

const LightboxImage: FC<LightboxImageProps> = ({image}) => {
	return (
		<div className={clsx('relative', 'h-[80svh]')}>
			<ImageFill
				src={image.image.src}
				width={image.image.width}
				height={image.image.height}
				hotspot={image.image.hotspot}
				alt={image.altText ?? ''}
				style={{objectFit: 'contain', objectPosition: 'center'}}
			/>
		</div>
	);
};

type ExitFullscreenButtonProps = {
	onClick: () => void;
};

const ExitFullscreenButton: FC<ExitFullscreenButtonProps> = ({onClick}) => {
	return (
		<button
			onClick={onClick}
			className={clsx('opacity-70', 'hover:opacity-100', 'top-2', 'absolute', 'right-2')}
			aria-label="Lukk fullskjerm bildegalleri"
		>
			<FullScreenExitIcon size={40} color="white" />
		</button>
	);
};
